import SIntentHandler from "../stripeable/index.js.erb"
require("theme/js/slick.min")
require("theme/js/select2.full.min")

$(document).ready ->
  viewAllTopPortfolios = (elem) ->
    link_tag = $(elem)
    link_tag.find('.slick-dots').before('\
      <p class="view-top-portfolios text-center font-weight-bold pb-3">\
        <a class="btn btn-lg text-white" href=' + link_tag.data('view-all') + ' style="font-size: 18px;">View All</a>\
      </p>\
    ') unless $('.view-top-portfolios').length > 0

  $('#slick-carousel-wrap-content').on('init', (event, slick) -> 
    viewAllTopPortfolios(@)
  ).on('breakpoint', (event, slick) -> 
    viewAllTopPortfolios(@)
  ).slick({
    infinite: true,
    slidesToShow: 4,
    slidesToScroll: 4,
    arrows: false,
    dots: true,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3
        }
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2
        }
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  });

  new SIntentHandler('subs2')
  new SIntentHandler('subs3')
  new SIntentHandler('subs4')
  new SIntentHandler('subs5')

  new SIntentHandler('subs_six2')
  new SIntentHandler('subs_six3')
  new SIntentHandler('subs_six4')
  new SIntentHandler('subs_six5')
