import {loadStripe} from '@stripe/stripe-js/pure';

export default class SIntentHandler {
  constructor(button) {
    // console.log('started');
    this.button = $(`#${button}`)
    this._stripe = null;
    this.host = window.location.origin
    this._init();
  }


  _set_card(elements) {
    const style = {
      base: {
        color: "#32325d",
      }
    };

    let card = elements.create("card", { style: style });
    card.mount("#scard-element");
  }


  _set_redirect() {
    const _this = this;
    this.button.click( () => {
      this._stripe.redirectToCheckout({
        sessionId: _this.button.data('secret')
      }).then(function (result) {
        if(result.error.message) {
          const path = ''; 
          window.location.href = [_this.host, path].join('');
        }
      });
    });
  }


  _config() {
    
    return '';
  }

  async _init() {
    this._stripe = await loadStripe(this._config());
    this._set_redirect();
  }
}